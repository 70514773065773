import React, {useContext, useEffect, useState} from 'react'
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, connectAuthEmulator, createUserWithEmailAndPassword, signInWithEmailAndPassword} from "firebase/auth";

const Urlcsrf = "/api/csrf";
const CsrfHeader = "x-csrf-token";

const FireBaseAuthTenantId = "prod-concours-k3zm1";
const DEVMODE = false;

var firebaseConfig = {
    apiKey: "AIzaSyAWoUiixr7Ga_sOfROEm70xaygstY0bjfg",
    authDomain: "concours.esitc-paris.fr",
};

const app = initializeApp(firebaseConfig);


// authInstance.tenantManager().authForTenant()
const SessionContext = React.createContext();
function Session({ children }: { children: React.ReactNode }) {
    let CsrfToken = null;

    let S = useContext(SessionContext);

    const auth = getAuth(app);
    auth.tenantId = FireBaseAuthTenantId;

    const [sessionState, setSessionState] = useState("Loading"); // Loading / LoggedIn // NotConnected
    const [user, setUser] = useState(null);

    const GetUserIdToken = () => {
        return user.getIdToken()
    }

    const Logout = (callback) : boolean => {
        if(user){
            auth.signOut(auth).then(() => {
                callback(true);
            }).catch((error) => {
                callback(false, error);
            });
        }else{
            callback(false, "NotLoggedIn");
        }

    }

    const LogoutWithUserObj = (CurUser, callback) : boolean => {
            auth.signOut(CurUser).then(() => {
                callback(true);
            }).catch((error) => {
                callback(false, error);
            });
    }

    const IsLoggedIn = () : boolean => {
        if(user){
            return true;
        }else{
            return false;
        }
    }

    const IsEmailConfirmed = () : boolean => {
        if(user){
            return user.emailVerified;
        }else{
            return null;
        }
    }

    const DisplayUser = () : string => {
        if(user){
            return user.email;
        }else{
            return false;
        }
    }

    const UserEmail = () : string => {
        if(user){
            return user.email;
        }else{
            return false;
        }
    }

    const CreateUserWithEmailAndPassword = (email, passwd, callback) => {
        createUserWithEmailAndPassword(auth, email, passwd)
            .then((userCredential) => {
                callback("OK", {user: userCredential.user});
            })
            .catch((error) => {
                callback("NOK", {errorCode: error.code, errorMessage: error.message})
            });
    }

    const SignInWithEmailAndPassword = (email, passwd, callback) => {
        signInWithEmailAndPassword(auth, email, passwd)
            .then((userCredential) => {
                console.log(userCredential.user);
                callback("OK", {user: userCredential.user})
            })
            .catch((error) => {
                callback("NOK", {errorCode: error.code, errorMessage: error.message})
            });

    }

    useEffect(() => {
        if(DEVMODE === true){
            connectAuthEmulator(auth, "http://localhost:9099");
        }
/*
        createUserWithEmailAndPassword(auth, "nicolas@berna.fr", "MotDepAsse")
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
            });*/

        onAuthStateChanged(auth, (user) => {
            setUser(user);
            if(user === null){
                setSessionState("NotConnected")
            }else{
                setSessionState("LoggedIn")
            }
    });}, [auth]);

   /* let InitSession = () => {
        if (sessionState.Initialized === false) {
            ReqGet(UrlStatusSession, async (response)=> {
                let json = await response.json();
                LoadSession(json.data);
            });
        }
    }*/

    let LogoutSession = (forced) => {

    }

    let LoadSession = (session) => {
        session.Initialized = true;
        session.Connected = true;
       // setSessionState(session);
    }

    let ReqGet = (url: string, callback, param) => {


            let Header = {
                'Accept': 'application/json',
                'X-Requested-With': "XMLHttpRequest",
            }

            if(typeof param !== 'undefined' && typeof param["AuthenticityToken"] !== 'undefined'){
                Header['Authenticity-Token'] = param["AuthenticityToken"];
            }

        fetch(url, {
            method: 'GET',
            headers: Header,
            mode: 'same-origin',
            cache: "no-store",
        }).then(response => {
            if (response.ok) {
                callback(response);
            } else {
                HandleFatalError(response, url, null, callback);
            }
        });
    }


    /*
        Gestion des erreurs fatales de l'API
     */
    async function HandleFatalError(resp, url, postdata, callback) {

       /* if(resp.status === 401){
            // Session invalide ou expirée
            let newSession = structuredClone(SessionDefaut);
            newSession.Initialized = true;
            setSessionState(newSession);
            return;
        }
*/
        setModalOpt({Show: true, SuperFatal: false});

        if(resp.status === 404){
            setModalOpt({Show: true, SuperFatal: true, actionBtn: false, ShowLoadingAnimation: false});


            let content = "Impossible d'executer la commande.";
            setDataModal({Titre: "Erreur 404", Content: content});
            return;
        }

        // Détection du code erreur
        let json;
        try {
            json = await resp.json();
        } catch(e) {
            // Trigger une erreur fatale
            setDataModal({Titre: "Erreur inconnue", Content: "Le système a retourné le message suivant :\n" + e.toString()});
            return;
        }

        setDataModal({Titre: "n°" + json.errorId + " - Erreur " + json.errorCode, Content: json.error+"\n\nSi le problème persiste, veuillez contacter l'administrateur en lui indiquant le code erreur n°" + json.errorId+"."});

    }


    /*
     user.getIdToken().then(idToken => {
     param :
        data : Payload ajouté dans la requete
        rescuecall : numéro d'itération de rescuecall
        AuthenticityToken : Clé de session
        AuthenticityCSRFToken : Jeton CSRF
     */
    let ReqPost = (url: string, callback , param) => {
       // if (CsrfToken === null) {
            GetCsrfToken((response) => {
                if (response.ok === true) {
                    param["AuthenticityCSRFToken"] = CsrfToken;
                    Fetcher(url, callback, param);
                } else {
                    callback(response);
                }

            });

        /*} else {
            param["AuthenticityCSRFToken"] = CsrfToken;
            Fetcher(url, callback, param);
        }*/
    }
    /*
    let ReqPost = (url: string, data: string, callback, rescuecall) => {
        if (CsrfToken === null) {
            GetCsrfToken((response) => {
                if (response.ok === true) {

                    Fetcher(url, data, callback, rescuecall);
                } else {
                    callback(response);
                }

            });

        } else {
            Fetcher(url, data, callback, rescuecall);
        }
    }
*/
    let GetCsrfToken = (callback) => {
        fetch(Urlcsrf).then(response => {
            if (response.ok) {
                CsrfToken = response.headers.get(CsrfHeader)
                callback(response);
            } else {
                callback(response);
            }
        });
    }
//
    let Fetcher = (url: string, callback, param) => {
        let rescuecall = (param["rescuecall"] !== undefined);
        let payloadData = (param["data"] !== undefined ? param["data"] : "");


        let Header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': "XMLHttpRequest",
        }

        if(typeof param["AuthenticityToken"] !== 'undefined'){
            Header['Authenticity-Token'] = param["AuthenticityToken"];
        }

        if(typeof param["AuthenticityCSRFToken"] !== 'undefined'){
            Header['Authenticity-CSRF-Token'] = param["AuthenticityCSRFToken"];
        }

            fetch(url, {
                method: 'POST',
                headers: Header,
                mode: 'same-origin',
                cache: "no-store",
                body: payloadData
            }).then(response => {
                if (!response.ok && response.headers.get("x-csrf-error") === "csrf-not-validated" && !rescuecall) {
                    console.log("CSRF incorrect. Réinitialisation du token");
                    S.CsrfToken = null;
                    param["rescuecall"] = true;
                    ReqPost(url, callback, param)
                } else {
                    if (rescuecall && response.headers.get("x-csrf-error") === "csrf-not-validated") {
                        console.log("La deuxième requête avec le nouveau CSRF a échoué.");
                    }
                    callback(response);
                }
            });
    };


    const [ModalOpt, setModalOpt] = useState({
        Show: false,
        SuperFatal: false,
        actionBtn: false,
        ShowLoadingAnimation: false,
        actionBtnTxt: "",
        CallBackBtn: null,
    });
    const handleClose = () => setModalOpt({Show: false, SuperFatal: false});
    const [dataModal, setDataModal] = useState({Titre: "Titre", Content: "Content."});



    const renderComponent = () => {
        let content = dataModal.Content.split('\n').map((data, i) => {
            return <p key={i}>{data}</p>
        });

        let BottomClose = <div className="modal-footer">
            <button type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}>Fermer
            </button>
        </div>

        let ActionBtn = <div className={"text-center"}><button type="button"
                                                               className="btn btn-primary"
                                                               onClick={ModalOpt.CallBackBtn}>{ModalOpt.actionBtnTxt}
        </button></div>


        let TopClose = <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>

        return <div className={"modal bg-dark" + ((ModalOpt.Show === true) ? ' d-block' : '') + " "+ ((ModalOpt.SuperFatal === false) ? 'bg-opacity-75' : '')}>
            <div className="modal-dialog">
                <div className="modal-content text-black">
                    <div className="modal-header">
                        <h5 className="modal-title">{dataModal.Titre}</h5>
                        { ((ModalOpt.SuperFatal === false) ? TopClose : '')}
                    </div>
                    <div className="modal-body">
                        {content}
                        { ModalOpt.actionBtn === true && ActionBtn}
                        { ModalOpt.ShowLoadingAnimation === true && <div className={"text-center"}><div className="spinner-grow"
                                                                                                        role="status">
                            <span className="visually-hidden">analyse en cours...</span>
                        </div></div>}

                    </div>
                    { ((ModalOpt.SuperFatal === false) ? BottomClose : '')}
                </div>
            </div>
        </div>;
    };
    renderComponent();
    let value = { ReqGet, ReqPost, UserEmail, LoadSession, sessionState, LogoutSession, user, GetUserIdToken, IsLoggedIn, IsEmailConfirmed, CreateUserWithEmailAndPassword, SignInWithEmailAndPassword, DisplayUser , Logout, LogoutWithUserObj};
    return  (
        <SessionContext.Provider
            value={value}
        >

            {children}

        </SessionContext.Provider>
    )
}

export { Session };


export default SessionContext;
